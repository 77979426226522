.App {
  height: 100vh;
  min-height:100%;

  text-align: center;
}
* {
  /* -webkit-touch-callout:none; */

  /* -webkit-user-select:none; */

  -webkit-tap-highlight-color:rgba(0, 0, 0, 0);
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.user-name {
  font-family: 'NanumSquareRound',sans-serif;
  color: #5f52b3;
}
.quiz-name {
  font-family: 'NanumSquareRound',sans-serif;
  color: #a8a8a8;
}
.stamp-ox {
  font-family: 'NanumSquareRound',sans-serif;
  color: #ffffff;
}
.confirm-background {
  background-color: 'transparent';
}
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #222;
}

.item {
  background-color: #ffffff;
  border: 1px solid rgba(153, 153, 153, 0.3);
  color: #363636;
  display: flex;
  flex-direction: column;
  font-size: 20px;
  font-weight: 400;
  margin-top: 34px;
  width: calc(33% - 15px);
  text-align: center;
}
#triangleSpan {
  position: absolute;
  top: 0;
  right: 0;
}
 
/* .tcontainer {
  position: relative;
}

.topright {
  position: absolute;
  top: 8px;
  right: 16px;
  font-size: 18px;
}

img { 
  width: 100%;
  height: auto;
  opacity: 0.3;
} */
#overlay {
  z-index: 35;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
}

#savePrompt {
  position: absolute;
  left: 50%;
  bottom: 22vh;
  transform: translateX(-50%);
  max-width: 80vw;

  font-family: sans-serif;
  font-weight: bold;
  font-size: 6vmin;
  color: black;
  text-align: center;

  background-color: white;
  padding: 2vmin;
  border-radius: 10px;
}
.scontainer {
  height: 200px;
  position: relative;
  border: 3px solid green;
}

.scenter {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 80%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.topright {
  position: absolute;
  top: 8px;
  right: 16px;
  font-size: 18px;
  background: url( 'logo192.png' ) no-repeat;
  border: none;
  width: 64px;
  height: 64px;
  cursor: pointer;
}
input.img-button {
  background: url( 'logo192.png' ) no-repeat;
  border: none;
  width: 32px;
  height: 32px;
  cursor: pointer;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@font-face {
  font-family: "NanumSquareRound";
  font-style: normal;
  font-weight: 300;
  src: local("NanumSquareRoundL"), /* computer */
    url("./font/NanumSquareRoundL.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("./font/NanumSquareRoundL.woff2") format("woff2"), /* Modern Browsers */
    url("./font/NanumSquareRoundL.woff") format("woff"), /* Modern Browsers */
    url("./font/NanumSquareRoundL.ttf") format("truetype"); /* Safari, Android, iOS */
}
@font-face {
  font-family: "NanumSquareRound";
  font-style: normal;
  font-weight: 400;
  src: local("NanumSquareRoundR"), /* computer */
    url("./font/NanumSquareRoundR.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("./font/NanumSquareRoundR.woff2") format("woff2"), /* Modern Browsers */    
    url("./font/NanumSquareRoundR.woff") format("woff"), /* Modern Browsers */
    url("./font/NanumSquareRoundR.ttf") format("truetype"); /* Safari, Android, iOS */
}
@font-face {
  font-family: "NanumSquareRound";
  font-style: normal;
  font-weight: 700;
  src: local("NanumSquareRoundB"), /* computer */
    url("./font/NanumSquareRoundB.woff2") format("woff2"), /* Modern Browsers */
    url("./font/NanumSquareRoundB.woff") format("woff"), /* Modern Browsers */
    url("./font/NanumSquareRoundB.ttf") format("truetype"); /* Safari, Android, iOS */
}
@font-face {
  font-family: "NanumSquareRound";
  font-style: normal;
  font-weight: 800;
  src: local("NanumSquareRoundEB"), /* computer */
    url("./font/NanumSquareRoundEB.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("./font/NanumSquareRoundEB.woff2") format("woff2"), /* Modern Browsers */
    url("./font/NanumSquareRoundEB.woff") format("woff"), /* Modern Browsers */
    url("./font/NanumSquareRoundEB.ttf") format("truetype"); /* Safari, Android, iOS */
}
@font-face {
  font-family: "NanumSquareRound";
  font-style: normal;
  font-weight: 900;
  src: local("NanumSquareRoundEB"), /* computer */
    url("./font/NanumSquareRoundEB.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("./font/NanumSquareRoundEB.woff2") format("woff2"), /* Modern Browsers */
    url("./font/NanumSquareRoundEB.woff") format("woff"), /* Modern Browsers */
    url("./font/NanumSquareRoundEB.ttf") format("truetype"); /* Safari, Android, iOS */
}
@font-face {
  font-family: "NanumSquareRound";
  font-style: normal;
  font-weight: 1000;
  src: local("NanumSquareRoundEB"), /* computer */
    url("./font/NanumSquareRoundEB.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("./font/NanumSquareRoundEB.woff2") format("woff2"), /* Modern Browsers */
    url("./font/NanumSquareRoundEB.woff") format("woff"), /* Modern Browsers */
    url("./font/NanumSquareRoundEB.ttf") format("truetype"); /* Safari, Android, iOS */
}