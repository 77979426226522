body {
  height: 100vh;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
.check {
  width: 410px;
  height: 750px;
  position: absolute;
  display: flex;
  align-items: "center";
  flex-direction: column;
}
/* .backbutton {
  margin: 0;
  position: absolute;
  top: 3%;
  left: 0%;
  margin-left: -.5em;
  margin-top: -.5em;
}
.center1 {
  margin: 0;
  position: absolute;
  top: 40%;
  left: 30%;
  margin-left: -.5em;
  margin-top: -.5em;
}
.center2 {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 30%;
  margin-left: -.5em;
  margin-top: -.5em;
}
.center3 {
  margin: 0;
  position: absolute;
  top: 60%;
  left: 30%;
  margin-left: -.5em;
  margin-top: -.5em;
}
.center4 {
  margin: 0;
  position: absolute;
  top: 70%;
  left: 30%;
  margin-left: -.5em;
  margin-top: -.5em;
}
.answerbutton {
  margin: 0;
  position: absolute;
  top: 90%;
  left: 5%;
  margin-left: -.5em;
  margin-top: -.5em;
}
.input-hidden {
  position: absolute;
  left: -9999px;
}

input[type=radio]:checked + label>img {
  border: 1px solid #fff;
  box-shadow: 0 0 3px 3px #090;
  background-image: "on.png";
}

/* Stuff after this is only to make things more pretty */
/*
input[type=radio] + label>img {
  border: 1px dashed #444;
  width: 150px;
  height: 150px;
  transition: 500ms all;
}

input[type=radio]:checked + label>img {
  transform: 
    rotateZ(-10deg) 
    rotateX(10deg);
} */

/*
 | //lea.verou.me/css3patterns
 | Because white bgs are boring.
*/
/* html {
  background-color: #fff;
  background-size: 100% 1.2em;
  background-image: 
    linear-gradient(
      90deg, 
      transparent 79px, 
      #abced4 79px, 
      #abced4 81px, 
      transparent 81px
    ),
    linear-gradient(
      #eee .1em, 
      transparent .1em
    );
} */